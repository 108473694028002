import { useAuthenticator } from "@aws-amplify/ui-react";
import { Fragment, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import DialogModal from "../components/dialog";
import { Disclosure, Menu, Transition } from "@headlessui/react";
import { useEffect } from "react";
import { Auth } from "aws-amplify";
import { useGetUserGroupsForUserQuery } from "../hasura.graphql";



function classNames(...classes: any) {
  return classes.filter(Boolean).join(" ");
}

export default function Header() {
  const { signOut } = useAuthenticator();
  const [signOutModal, setSignOutModal] = useState<boolean>(false);
  const { pathname } = useLocation();
  const [userEmail, setUserEmail] = useState<string | null>(null);
  const [financialPermissionsChecked, setFinancialPermissionsChecked] = useState(false);
  
  useEffect(() => {
    const fetchUserEmail = async () => {
      try {
        const user = await Auth.currentAuthenticatedUser();
        const email = user.attributes.email;
        setUserEmail(email);
        console.log("Logged-in user's email:", email);
      } catch (error) {
        console.error("Error fetching user email:", error);
      }
    };

    fetchUserEmail();
  }, []);

  const userGroupList = useGetUserGroupsForUserQuery({
    variables: { user_email: userEmail },
    skip: !userEmail, 
    onCompleted: () => {
      setFinancialPermissionsChecked(true);
    }
  });

  const userGroups = userGroupList.data?.financial_permissions.map(
    (item) => item.user_group
  );

  const navigation = [
    { name: "Dashboards", href: "/dashboards", notInclude: null },
    { name: "Customers", href: "/customers", notInclude: null },
    { name: "Channels", href: "/channels", notInclude: "/customers" },
    { name: "Platforms", href: "/platforms", notInclude: null },
    {
      name: "Deliveries",
      href: "/deliveries",
      notInclude: "/customers",
    },
    { name: "Reports", href: "/reports", notInclude: null },
  ];

  if (userGroups?.includes("admin")) {
    navigation.push({ name: "Financial Permissions", href: "/financial-permissions", notInclude: null });
  }

  if (!financialPermissionsChecked) {
    return null;
  }

  return (
    <>
      <div className="min-h-full sticky top-0 z-30 w-full shadow-md">
        <Disclosure as="nav" className="bg-white border-b border-gray-200">
          {() => (
            <>
              <div className="mx-auto px-4 sm:px-6 lg:px-8">
                <div className="flex justify-between h-14">
                  <div className="flex">
                    <div className="flex-shrink-0 flex items-center">
                      <img
                        className="hidden lg:block my-2"
                        src="/assets/cdp-logo.svg"
                        alt="Workflow"
                      />
                    </div>
                    <div className="hidden sm:-my-px sm:ml-10 sm:flex sm:space-x-8">
                      {navigation.map((item) => (
                        <a
                          key={item.name}
                          href={item.href}
                          className={classNames(
                            pathname.includes(item.href) &&
                              !pathname.includes(item.notInclude!)
                              ? "border-orange-500 text-orange-500 hover:text-orange-500"
                              : "border-transparent hover:border-orange-300 hover:text-orange-300",
                            "inline-flex items-center px-1 pt-1 border-b-2 text-base font-medium hover:no-underline focus:no-underline focus:text-inherit"
                          )}
                        >
                          {item.name}
                        </a>
                      ))}
                    </div>
                  </div>
                  <div className="hidden sm:ml-6 sm:flex sm:items-center">
                    <Menu as="div" className="mx-3 relative">
                      <div>
                        <Menu.Button className="bg-white rounded-full flex text-sm focus:outline-none">
                          <img src="/assets/help.svg" alt="help" />
                        </Menu.Button>
                      </div>
                      <Transition
                        as={Fragment}
                        enter="transition ease-out duration-200"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                      >
                        <Menu.Items className="origin-top-right z-10 absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                          <Menu.Item>
                            {({ active }) => (
                              <a
                                href="/docs.html"
                                target="_blank"
                                className={classNames(
                                  active ? "bg-gray-100" : "",
                                  "block px-4 py-2 text-sm text-gray-700 hover:no-underline"
                                )}
                              >
                                CDP Rest APIs
                              </a>
                            )}
                          </Menu.Item>
                          <Menu.Item>
                            {({ active }) => (
                              <a
                                href="/assets/userguide_and_faq.pdf"
                                target="_blank"
                                className={classNames(
                                  active ? "bg-gray-100" : "",
                                  "block px-4 py-2 text-sm text-gray-700 hover:no-underline"
                                )}
                              >
                                User Guide and FAQ
                              </a>
                            )}
                          </Menu.Item>
                        </Menu.Items>
                      </Transition>
                    </Menu>
                    <Menu as="div" className="ml-3 relative">
                      <div>
                        <Menu.Button className="bg-white rounded-full flex text-sm focus:outline-none">
                          <img src="/assets/profile.svg" alt="profile" />
                        </Menu.Button>
                      </div>
                      <Transition
                        as={Fragment}
                        enter="transition ease-out duration-200"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                      >
                        <Menu.Items className="origin-top-right z-10 absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                          <Menu.Item>
                            {({ active }) => (
                              <a
                                href="https://cdp.ideas.aha.io/ideas/new"
                                target="_blank"
                                className={classNames(
                                  active ? "bg-gray-100" : "",
                                  "block px-4 py-2 text-sm text-gray-700 hover:no-underline"
                                )}
                                rel="noreferrer"
                              >
                                Give Feedback
                              </a>
                            )}
                          </Menu.Item>
                          <Menu.Item>
                            {({ active }) => (
                              <Link
                                to="#"
                                className={classNames(
                                  active ? "bg-gray-100" : "",
                                  "block px-4 py-2 text-sm text-gray-700 hover:no-underline"
                                )}
                                onClick={() => setSignOutModal(true)}
                              >
                                Sign out
                              </Link>
                            )}
                          </Menu.Item>
                        </Menu.Items>
                      </Transition>
                    </Menu>
                    {signOutModal && (
                      <DialogModal
                        modalShow={signOutModal}
                        onClose={setSignOutModal}
                      >
                        <div>
                          <span className="mt-2">
                            Are you sure you want to sign out ?
                          </span>
                          <div className="flex justify-between mt-3">
                            <button
                              className="px-3 py-2"
                              onClick={() => setSignOutModal(false)}
                            >
                              Cancel
                            </button>
                            <button
                              className="px-3 py-2 bg-red-500 text-white"
                              onClick={() => signOut()}
                            >
                              Sign Out
                            </button>
                          </div>
                        </div>
                      </DialogModal>
                    )}
                  </div>
                </div>
              </div>
            </>
          )}
        </Disclosure>
      </div>
    </>
  );
}