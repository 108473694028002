import { useState } from "react";
import DataQuality from "../components/data-quality";
import DQOverview from "../components/dq-overview";
import Tabs from "../components/tabs";
//import { useCDPRole } from "../cdp-role-context";
//import { useFeatureFlagQuery } from "../hasura.graphql";

const Dashboard: React.FC = () => {
  const [tab, setTab] = useState<string>("Overview");
  //const { setCDPRole } = useCDPRole();

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
 // const role_data = useFeatureFlagQuery({
   // onCompleted: (data) => {
     // setCDPRole(data.feature_flag.map((feat) => feat.feature));
    //},
 // });
  
  const tabList = [
    { name: "Overview" },
    { name: "Data Quality" },
    //CDPRole.includes("channel-edit") && { name: "Financials" },
  ].filter(Boolean);
  return (
    <div style={{ backgroundColor: "#F5FAFE" }}>
      <div className="mb-4 sticky top-14 z-10">
        <Tabs currentTab={tab} tabList={tabList} toggleTab={setTab} />
      </div>
      <div className="mx-6">
        {tab === "Overview" && <DQOverview />}
        {tab === "Data Quality" && <DataQuality />}
        {/* {tab === "Financials" && <DQFinancials />} */}
      </div>
    </div>
  );
};

export default Dashboard;