import React, { useState, useRef } from "react";
import { FixedSizeList as List } from "react-window";
import { useCustomerQuery } from "../hasura.graphql";
import { useMutation, useLazyQuery } from "@apollo/client";
import Loader from "../common/loader";
import ErrorBoundary from "../components/error-boundary";
import gql from "graphql-tag";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const INSERT_FINANCIAL_PERMISSIONS = gql`
  mutation InsertFinancialPermissions($user_email: String!, $user_group: String!, $amagi_id: String!) {
    insert_financial_permissions(objects: [
      { user_email: $user_email, user_group: $user_group, amagi_id: $amagi_id }
    ], on_conflict: { constraint: financial_permissions_pkey, update_columns: [] }) {
      affected_rows
    }
  }
`;

const GET_FINANCIAL_PERMISSION = gql`
  query GetFinancialPermission($user_email: String!, $amagi_id: String!) {
    financial_permissions(where: { user_email: { _eq: $user_email }, amagi_id: { _eq: $amagi_id } }) {
      user_email
      amagi_id
    }
  }
`;

const DELETE_FINANCIAL_PERMISSION = gql`
  mutation DeleteFinancialPermission($user_email: String!, $amagi_id: String!) {
    delete_financial_permissions(where: { user_email: { _eq: $user_email }, amagi_id: { _eq: $amagi_id } }) {
      affected_rows
    }
  }
`;

const FinancialPermissions: React.FC = () => {
  const [selectedCustomers, setSelectedCustomers] = useState<string[]>([]);
  const [userEmail, setUserEmail] = useState<string>("");
  const [userGroup, setUserGroup] = useState<string>("");
  const [dataLoaded, setDataLoaded] = useState(false);
  const [selectAll, setSelectAll] = useState(false);

  const { data, loading, error } = useCustomerQuery({
    fetchPolicy: dataLoaded ? "cache-first" : "cache-and-network",
    onCompleted: () => setDataLoaded(true),
  });
  
  const [insertFinancialPermissions] = useMutation(INSERT_FINANCIAL_PERMISSIONS);
  const [deleteFinancialPermission] = useMutation(DELETE_FINANCIAL_PERMISSION);
  
  const [checkFinancialPermission] = useLazyQuery(GET_FINANCIAL_PERMISSION);
  
  const [isOpen, setIsOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const dropdownRef = useRef<HTMLDivElement | null>(null);

  const handleCheckboxChange = (amagiId: string) => {
    setSelectedCustomers(prevState =>
      prevState.includes(amagiId)
        ? prevState.filter(id => id !== amagiId)
        : [...prevState, amagiId]
    );
  };

  const handleSelectAll = () => {
    if (selectAll) {
      setSelectedCustomers([]);
    } else {
      const allCustomerIds = customers.map(cust => cust.amagi_id);
      setSelectedCustomers(allCustomerIds);
    }
    setSelectAll(!selectAll);
  };

  const handleSubmit = async () => {
    if (userEmail && userGroup && (selectedCustomers.length > 0 || selectAll)) {
      try {
        const amagiIdsToInsert = selectAll 
          ? ["all"] 
          : selectedCustomers;

        // Check if permission exists for each selected amagi_id
        const permissionChecks = await Promise.all(amagiIdsToInsert.map(amagiId => 
          checkFinancialPermission({ variables: { user_email: userEmail, amagi_id: amagiId } })
        ));

        // Check if any of the permissions already exist
        const existingPermissions = permissionChecks.some(check => check.data.financial_permissions.length > 0);

        if (existingPermissions) {
          // If existing, delete the permissions for the user_email and amagi_id
          await Promise.all(
            amagiIdsToInsert.map(amagiId =>
              deleteFinancialPermission({
                variables: { user_email: userEmail, amagi_id: amagiId },
              })
            )
          );
        }

        // Insert the new permissions separately for each Amagi ID
        for (const amagiId of amagiIdsToInsert) {
          await insertFinancialPermissions({
            variables: {
              user_email: userEmail,
              user_group: userGroup,
              amagi_id: amagiId,
            },
          });
        }

        toast.success("Permission Updated Successfully");
  
        setUserEmail("");
        setUserGroup("");
        setSelectedCustomers([]);
        setIsOpen(false);
        setSearchTerm("");
        setSelectAll(false);
      } catch (err) {
        console.error("Error executing mutation:", err);
        toast.error("Permission Updation Failed");
      }
    } else {
      alert("Please fill in all fields and select at least one customer.");
    }
  };

  const closeDropdown = () => {
    setIsOpen(false);
    setSearchTerm("");
    setSelectedCustomers([]);
    setSelectAll(false);
  };

  if (loading) return (
    <div className="mx-6 mt-4">
      <Loader />
    </div>
  );

  if (error) return <div>Error loading customers.</div>;
  if (!data) return <div>No customer data available.</div>;

  const customers = data.customer.map((cust: any) => ({
    amagi_id: cust.amagi_id,
    account_name: cust.account_name,
    logo: cust.logo,
    status: cust.status
  }));

  const filteredCustomers = customers
    .filter(customer =>
      customer.status === "Customer" &&
      (customer.account_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
       customer.amagi_id.toLowerCase().includes(searchTerm.toLowerCase()))
    )
    .sort((a, b) => a.amagi_id.localeCompare(b.account_name));

  const itemSize = 50;
  const listHeight = Math.min(filteredCustomers.length * itemSize, 2000); 

  return (
    <ErrorBoundary>
      <ToastContainer />
      <div className="mx-6 mt-4">
        <div className="mb-2">
          <label className="block text-sm font-medium text-gray-700 mb-2">Select Customers:</label>
          <div 
            className="flex justify-between items-center border border-gray-300 rounded-md p-2 cursor-pointer bg-white"
            onClick={() => setIsOpen(!isOpen)}
            ref={dropdownRef}
          >
            <span>Select Customers</span>
            <span className="transform transition-transform duration-200">
              {isOpen ? '▼' : '▲'}
            </span>
          </div>
          
          {isOpen && (
            <div className="border border-gray-300 rounded-md mt-1 bg-white relative">
              <div className="flex justify-between items-center p-2 bg-gray-200">
                <input
                  type="text"
                  value={searchTerm}
                  onChange={e => setSearchTerm(e.target.value)}
                  className="mt-1 p-2 block w-full border border-gray-300 rounded-md"
                  placeholder="Search by name or amagi ID"
                />
                <button onClick={closeDropdown} className="text-red-500 ml-2">
                  &times;
                </button>
              </div>
              <div className="p-2">
                <label className="flex items-center mb-2">
                  <input
                    type="checkbox"
                    checked={selectAll}
                    onChange={handleSelectAll}
                    className="mr-2"
                  />
                  Select All
                </label>
              </div>
              <List
                height={listHeight}
                itemCount={filteredCustomers.length}
                itemSize={itemSize}
                width="100%"
              >
                {({ index, style }) => {
                  const customer = filteredCustomers[index];
                  return (
                    <div key={customer.amagi_id} style={style} className="flex items-center space-x-2 p-2 hover:bg-gray-100 cursor-pointer">
                      <input
                        type="checkbox"
                        checked={selectedCustomers.includes(customer.amagi_id)}
                        onChange={() => handleCheckboxChange(customer.amagi_id)}
                      />
                      <LazyLoadImage
                        className="w-8 h-8 rounded-full"
                        src={customer.logo && customer.logo.startsWith("uploads/")
                          ? `${customer.logo}`
                          : customer.logo
                          ? `/logos/${customer.logo}`
                          : `https://via.placeholder.com/300x200.png/C1CFFB/1924C1?text=${customer.account_name}`
                        }
                        alt={customer.account_name}
                        placeholderSrc={`https://via.placeholder.com/300x200.png/C1CFFB/1924C1?text=${customer.account_name}`}
                      />
                      <span>{customer.account_name} ({customer.amagi_id})</span>
                    </div>
                  );
                }}
              </List>
            </div>
          )}
        </div>

        <div className="flex flex-wrap mb-4">
          {selectAll ? (
            <div className="flex items-center bg-blue-100 text-blue-800 rounded-full px-2 py-1 mr-2 mb-2">
              <span>All</span>
              <button
                className="ml-2 text-red-600"
                onClick={() => handleCheckboxChange("all")}
              >
                &times;
              </button>
            </div>
          ) : (
            selectedCustomers.map(customerId => (
              <div key={customerId} className="flex items-center bg-blue-100 text-blue-800 rounded-full px-2 py-1 mr-2 mb-2">
                <span>{customerId}</span>
                <button
                  className="ml-2 text-red-600"
                  onClick={() => handleCheckboxChange(customerId)}
                >
                  &times;
                </button>
              </div>
            ))
          )}
        </div>

        <div className="mb-2">
          <label className="block text-sm font-medium text-gray-700 mb-2">User Email:</label>
          <input
            type="email"
            value={userEmail}
            onChange={(e) => setUserEmail(e.target.value)}
            className="block w-full border border-gray-300 rounded-md p-2"
            placeholder="Enter User Email"
          />
        </div>

        <div className="mb-4">
          <label className="block text-sm font-medium text-gray-700 mb-2">User Group:</label>
          <input
            type="text"
            value={userGroup}
            onChange={(e) => setUserGroup(e.target.value)}
            className="block w-full border border-gray-300 rounded-md p-2"
            placeholder="Enter User Group"
          />
        </div>

        <button
          onClick={handleSubmit}
          className="bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600"
        >
          Submit
        </button>
      </div>
    </ErrorBoundary>
  );
};

export default FinancialPermissions;
